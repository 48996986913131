import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { RuleSet } from '../../models/site-checkout/RuleSet.model';

@Component({
  selector: 'app-fdd-ruleset-view',
  templateUrl: './fdd-ruleset-view.component.html',
  styleUrls: ['./fdd-ruleset-view.component.scss']
})
export class FDDRuleSetViewComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public data: RuleSet) {}
}
