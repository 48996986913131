import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { catchError, mergeMap, tap } from 'rxjs/operators';
import { Observable, Subject, interval, of } from 'rxjs';
import { ConfigurationService } from 'src/app/shared/services/configuration.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { BaseWrapperDirective } from 'src/app/shared/classes/base-wrapper';
import { getBlockDefinitions } from '@75f/blockly-lib';
import dayjs from 'dayjs';
import * as Blockly from 'blockly';
import { FlowStatistics } from '../models/FlowStatistics.model';
import { BlockStatistics } from '../models/BlockStatistics.model';
import { RecurringSchedule } from '../enums/RecurringSchedule.enum';
import { EventStatus } from '../enums/EventStatus.enum';
import { Site } from '../models/Site.model';
import { DatetimeRange } from 'src/app/shared/components/controls/datetime-picker/datetime-picker.component';
import { NavigationTab } from '../components/auto-cx-layout/auto-cx-layout.component';
import { AutoCommissioningBlock, AutoCommissioningFlow, AutoCommissioningTest, AutoCommissioningTestRequest } from '../models/AutoCommissioning.model';

export enum FlowControlBlock {
    START = "flow-start",
    STOP = "flow-stop"
}

@Injectable({
    providedIn: 'root'
})
export class AutoCxService extends BaseWrapperDirective {
    buildingAnalyticsUrl: string;
    
    sites = new Subject<Site[]>();
    sites$ = this.sites.asObservable();

    datetimeRange = new Subject<DatetimeRange>();
    datetimeRange$ = this.datetimeRange.asObservable();
    
    activeSiteId = new Subject<string>();
    activeSiteId$ = this.activeSiteId.asObservable();

    loadBlockLibrary = new Subject<void>();
    loadBlockLibrary$ = this.loadBlockLibrary.asObservable();

    loadFlowLibrary = new Subject<void>();
    loadFlowLibrary$ = this.loadFlowLibrary.asObservable();

    flowsLoaded = new Subject<void>();
    flowsLoaded$ = this.flowsLoaded.asObservable();

    loadTests = new Subject<void>();
    loadTests$ = this.loadTests.asObservable();

    navigate = new Subject<NavigationTab>();
    navigate$ = this.navigate.asObservable();
    
    confirm = new Subject<boolean>();
    confirm$ = this.confirm.asObservable();

    refreshIntervalSource = interval(5000);

    defaultDatetimeRange: DatetimeRange = {
        startDatetime: dayjs().local().add(-3, 'days').startOf('day'),
        stopDatetime: dayjs().local().add(3, 'days').endOf('day')
    }

    schedules: any[] = [
        {
            displayName: "None",
            type: RecurringSchedule.NONE,
            days: 0,
            description: "Test will only run at the scheduled date & time"
        },
        {
            displayName: "Monthly",
            type: RecurringSchedule.MONTHLY,
            days: 30,
            description: "Test will run at the scheduled time every month"
        },
        {
            displayName: "Quarterly",
            type: RecurringSchedule.QUARTERLY,
            days: 90,
            description: "Test will run at the scheduled time every 3 months"
        },
        {
            displayName: "Yearly",
            type: RecurringSchedule.YEARLY,
            days: 365,
            description: "Test will run at the scheduled time annually"
        }
    ]

    executionHooks: any = {
        // System blocks
        'system_heating_loop_override': (block: Blockly.Block) => this.systemHeatingLoopOverrideHook(block),
        'system_cooling_loop_override': (block: Blockly.Block) => this.systemCoolingLoopOverrideHook(block),
        'system_fan_loop_override': (block: Blockly.Block) => this.systemFanLoopOverrideHook(block),
        // Zone blocks
        'zone_heating_loop_override': (block: Blockly.Block) => this.zoneHeatingLoopOverrideHook(block),
        'zone_cooling_loop_override': (block: Blockly.Block) => this.zoneCoolingLoopOverrideHook(block),
        'zone_fan_loop_override': (block: Blockly.Block) => this.zoneFanLoopOverrideHook(block),
        'zone_damper_override': (block: Blockly.Block) => this.zoneDamperOverrideHook(block),
        // Generic blocks
        'wait': (block: Blockly.Block) => this.waitHook(block),
        'point_write_query': (block: Blockly.Block) => this.genericOverrideHook(block),
        'tuner_override': (block: Blockly.Block) => this.tunerOverrideHook(block),
    }

    constructor(
        private readonly http: HttpClient,
        private readonly configService: ConfigurationService,
        private readonly storageService: StorageService
    ) {
        super();
        this.buildingAnalyticsUrl = this.configService.get('buildingAnalyticsUrl');

        this.subs.sink = this.loadBlockLibrary$.pipe(
            tap(() => {
                this.getBlocks().pipe(
                    tap((blocks) => {
                        this.savedBlocks.startBlock = blocks.find(b => b.opCode == FlowControlBlock.START);
                        this.savedBlocks.stopBlock = blocks.find(b => b.opCode == FlowControlBlock.STOP);
                        this.savedBlocks.publishedBlocks = blocks.filter(b => b.isPublished && b.opCode != FlowControlBlock.START && b.opCode != FlowControlBlock.STOP);
                        this.savedBlocks.privateBlocks = blocks.filter(b => !b.isPublished);
                    })
                ).subscribe();
            })
        ).subscribe();

        this.subs.sink = this.loadFlowLibrary$.pipe(
            mergeMap(() => this.getFlows()),
            tap((flows) => {
                this.savedFlows.publishedFlows = flows.filter(f => f.isPublished);
                this.savedFlows.privateFlows = flows.filter(f => !f.isPublished);
                this.flowsLoaded.next();
            })
        ).subscribe();

        this.loadBlockLibrary.next();
        this.loadFlowLibrary.next();

        // Initialize 75F Blockly library & inject code
        const blockDefinitions = getBlockDefinitions();
        Blockly.defineBlocksWithJsonArray(blockDefinitions);
        blockDefinitions.forEach((blockDef) => {
            (Blockly as any).JavaScript[blockDef.type] = this.executionHooks[blockDef.type];
        })
    }

    savedBlocks: any = {
        startBlock: null,
        stopBlock: null,
        publishedBlocks: [],
        privateBlocks: []
    }

    savedFlows: any = {
        publishedFlows: [],
        privateFlows: []
    }

    isTerminalBlock(block: any): boolean {
        return block.opCode == FlowControlBlock.START || block.opCode == FlowControlBlock.STOP;
    }

    getFlow(flowId: string) {
        return this.savedFlows.publishedFlows.find(f => f.flowId == flowId) || this.savedFlows.privateFlows.find(b => b.flowId == flowId);
    }

    getBlock(opCode: string) {
        if (opCode == FlowControlBlock.START) return this.savedBlocks.startBlock;
        if (opCode == FlowControlBlock.STOP) return this.savedBlocks.stopBlock;
        return this.savedBlocks.publishedBlocks.find(b => b.opCode == opCode) || this.savedBlocks.privateBlocks.find(b => b.opCode == opCode);
    }

    /**
     * Evaluates a single Blockly code block
     * @param block Blockly object
     * @returns Object containing system & zone runtime metrics
     */
    getBlockStatistics(xmlData: string): BlockStatistics {
        let workspace = new Blockly.Workspace();
        let dom = Blockly.Xml.textToDom(xmlData);
        Blockly.Xml.domToWorkspace(dom, workspace);
        return this.evaluateWorkspace(workspace)
    }

    evaluateWorkspace(workspace: Blockly.Workspace): BlockStatistics {
        const blockCode = (Blockly as any).JavaScript.workspaceToCode(workspace);
        let code = `
        () => {
            // Helper functions
            function toMinutes(_value, _units) {
                if (_units == 'hr') return _value * 60;
                if (_units == 'sec') return _value/60;
                if (_units == 'ms') return _value/60000;
                return _value; // default to minutes
            }
    
            function toTags(query) {
                return query.split(" ");
            }
    
            // Declare constants
            const MINIMUM_TEMP = 50;
            const MAXIMUM_TEMP = 120;
    
            // Declare global variables
            let GLOBAL_MAX_COMMAND_DURATION = 0;
            let GLOBAL_TOTAL_DELAY = 0;
            let GLOBAL_SYSTEM_COMMAND_DURATION = 0;
            let GLOBAL_ZONE_COMMAND_DURATION = 0;
            let GLOBAL_IS_SYSTEM_LOOP_CONFLICT = false; // Indicates true if both heating & cooling are non-zero at the same time
            let GLOBAL_IS_FAN_LOOP_ENABLED = true; // Indicates if any of the blocks is missing a fan-loop override
            let GLOBAL_IS_SYSTEM_OVERRIDE = false; // Indicates if the block overrides a system-level command
            let GLOBAL_IS_TEMP_OUT_OF_BOUNDS = false; // Indicates if a temperature override exceeds the predetermined limits (50 - 120F)
    
            ${blockCode}
    
            return {
                systemCommandDuration: GLOBAL_SYSTEM_COMMAND_DURATION,
                zoneCommandDuration: GLOBAL_ZONE_COMMAND_DURATION,
                totalDelay: GLOBAL_TOTAL_DELAY,
                maxCommandDuration: GLOBAL_MAX_COMMAND_DURATION,
                isSystemLoopConflict: GLOBAL_IS_SYSTEM_LOOP_CONFLICT,
                isSystemOverride: GLOBAL_IS_SYSTEM_OVERRIDE,
                isFanLoopEnabled: GLOBAL_IS_FAN_LOOP_ENABLED,
                isTempOutOfBounds: GLOBAL_IS_TEMP_OUT_OF_BOUNDS
            };
        }
        `
        let func = eval(code);
        const { systemCommandDuration, zoneCommandDuration, totalDelay, maxCommandDuration, isSystemLoopConflict, isSystemOverride, isFanLoopEnabled, isTempOutOfBounds } = func();
        return {
            systemCommandDuration,
            zoneCommandDuration,
            totalDelay,
            maxCommandDuration,
            isSystemLoopConflict,
            isSystemOverride,
            isFanLoopEnabled,
            isTempOutOfBounds
        };
    }

    getFlowStatistics(selectedFlow): FlowStatistics {
        let nodeExecutionLookup: { [key: string]: BlockStatistics; } = {};

        selectedFlow.nodes.forEach((node) => {
            if (node.opCode != FlowControlBlock.START && node.opCode != FlowControlBlock.STOP) {
                let block = this.getBlock(node.opCode);
                let metrics = this.getBlockStatistics(block.executionData.xml);
                nodeExecutionLookup[node.opCode] = metrics;
            } else {
                nodeExecutionLookup[node.opCode] = {
                    systemCommandDuration: 0,
                    zoneCommandDuration: 0,
                    totalDelay: 0,
                    maxCommandDuration: 0,
                    isTempOutOfBounds: false,
                    isSystemLoopConflict: false,
                    isSystemOverride: false,
                    isFanLoopEnabled: true
                }
            }
        })

        /**
         * For each node in the selected flow, evaluate individual runtimes.
         * Then traverse the flow-graph to evaluate total runtime.
         * Validate that fan is enabled for system-level tests
         */
        let runtimeMinutes = 0;
        let isFanEnabledForAllSystemLevelBlocks = true;
        let isTempOutOfBounds = false;

        let nextNode = (fromNode, currentDuration) => {
            fromNode.outputs.forEach((output) => {
                let connections = output.connections;

                // If the fan is enabled in at least one block in a segment (a segment is one or more blocks being executed in parallel), 
                // Then it is a valid sequence
                connections.forEach((connection) => {
                    let toNode = selectedFlow.nodes.find(n => n.nodeId == connection.nodeId);


                    let blockSystemCommandDuration = nodeExecutionLookup[toNode.opCode].systemCommandDuration;
                    let blockZoneCommandDuration = nodeExecutionLookup[toNode.opCode].zoneCommandDuration;
                    let blockDelayDuration = nodeExecutionLookup[toNode.opCode].totalDelay;
                    let isFanLoopEnabled = nodeExecutionLookup[toNode.opCode].isFanLoopEnabled;
                    let isSystemOverride = nodeExecutionLookup[toNode.opCode].isSystemOverride;

                    if (isSystemOverride && !isFanLoopEnabled) {
                        isFanEnabledForAllSystemLevelBlocks = false;
                    }

                    if (nodeExecutionLookup[toNode.opCode].isTempOutOfBounds) {
                        isTempOutOfBounds = true;
                    }

                    if (toNode.opCode == FlowControlBlock.STOP) { // terminating condition
                        runtimeMinutes = Math.max(currentDuration, runtimeMinutes);
                    }
                    else {
                        nextNode(toNode, currentDuration + blockDelayDuration + Math.max(blockSystemCommandDuration, blockZoneCommandDuration));
                    }
                })
            })
        }

        // recursively traverse the flow graph to get the estimated runtime
        let startNode = selectedFlow.nodes.find(n => n.opCode == FlowControlBlock.START);
        nextNode(startNode, 0);

        return { runtimeMinutes, isFanEnabledForAllSystemLevelBlocks, isTempOutOfBounds };
    }

    getOperationalNodes(selectedFlow: AutoCommissioningFlow) {
        return selectedFlow.nodes.filter(node => node.opCode != FlowControlBlock.START && node.opCode != FlowControlBlock.STOP)
    }

    setHttpParams(params) {
        let httpParams = new HttpParams();
        if (params) {

            Object.keys(params).forEach((key, index) => {
                if (params[key] instanceof Object) {
                    httpParams = httpParams.append(key, JSON.stringify(params[key]));
                } else {
                    httpParams = httpParams.append(key, params[key]);
                }
            });
        }
        return httpParams;
    }

    private handleError(error: Error): Promise<any> {
        console.error('An error occurred', error);
        return Promise.reject(error);
    }

    private get jsonTypeHeaders() {
        return {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.storageService.bearerToken}`
            })
        };
    }

    createBlock(newBlock: AutoCommissioningBlock): Observable<any> {
        return this.http.post(this.buildingAnalyticsUrl + '/auto-commissioning/blocks', newBlock, this.jsonTypeHeaders).pipe(catchError(this.handleError));
    }

    updateBlock(updateBlock: AutoCommissioningBlock): Observable<any> {
        return this.http.put(this.buildingAnalyticsUrl + '/auto-commissioning/blocks', updateBlock, this.jsonTypeHeaders).pipe(catchError(this.handleError));
    }

    removeBlock(opCode: string): Observable<any> {
        return this.http.delete(this.buildingAnalyticsUrl + `/auto-commissioning/blocks/${opCode}`, this.jsonTypeHeaders).pipe(catchError(this.handleError));
    }

    getBlocks(): Observable<AutoCommissioningBlock[]> {
        return this.http.get(this.buildingAnalyticsUrl + '/auto-commissioning/blocks', this.jsonTypeHeaders).pipe(catchError(this.handleError));
    }

    getFlows(): Observable<AutoCommissioningFlow[]> {
        return this.http.get(this.buildingAnalyticsUrl + '/auto-commissioning/flows', this.jsonTypeHeaders).pipe(catchError(this.handleError));
    }

    getFlowById(flowId: string): Observable<AutoCommissioningFlow> {
        return this.http.get(this.buildingAnalyticsUrl + `/auto-commissioning/flows/${flowId}`, this.jsonTypeHeaders).pipe(catchError(this.handleError));
    }

    createFlow(payload: AutoCommissioningFlow): Observable<any> {
        return this.http.post(this.buildingAnalyticsUrl + '/auto-commissioning/flows/', payload, this.jsonTypeHeaders).pipe(catchError(this.handleError));
    }

    updateFlow(flow: AutoCommissioningFlow): Observable<any> {
        return this.http.put(this.buildingAnalyticsUrl + `/auto-commissioning/flows/${flow.flowId}`, flow, this.jsonTypeHeaders).pipe(catchError(this.handleError));
    }

    removeFlow(flowId: string): Observable<any> {
        return this.http.delete(this.buildingAnalyticsUrl + `/auto-commissioning/flows/${flowId}`, this.jsonTypeHeaders).pipe(catchError(this.handleError));
    }

    createTest(test: AutoCommissioningTestRequest): Observable<any> {
        return this.http.post(this.buildingAnalyticsUrl + '/auto-commissioning/tests/', test, this.jsonTypeHeaders).pipe(catchError(this.handleError));
    }

    getTestById(testId: string): Observable<AutoCommissioningTest> {
        return this.http.get(this.buildingAnalyticsUrl + `/auto-commissioning/tests/${testId}`, this.jsonTypeHeaders).pipe(catchError(this.handleError));
    }

    getTests(siteIds: string[], dateTimeFrom?: string, dateTimeThru?: string, testStatus?: EventStatus): Observable<AutoCommissioningTest[]> {
        let query = `${siteIds.join(",")}`;
        if (dateTimeFrom != null && dateTimeThru != null) query += `&dateTimeFrom=${dateTimeFrom}&dateTimeThru=${dateTimeThru}`;
        if (testStatus != null) query += `&testStatus=${testStatus}`;
        return this.http.get(this.buildingAnalyticsUrl + `/auto-commissioning/tests?siteIds=${query}`, this.jsonTypeHeaders).pipe(catchError(this.handleError));
    }

    getTestsForFlows(flowIds: string[]): Observable<AutoCommissioningTest[]> {
        let query = `${flowIds.join(",")}`;
        return this.http.get(this.buildingAnalyticsUrl + `/auto-commissioning/tests?flowIds=${query}`, this.jsonTypeHeaders).pipe(catchError(this.handleError));
    }

    cancelTest(testId: string) {
        return this.http.post(this.buildingAnalyticsUrl + `/auto-commissioning/tests/${testId}/cancel`, null, this.jsonTypeHeaders).pipe(catchError(this.handleError));
    }

    deleteTest(testId: string) {
        return this.http.delete(this.buildingAnalyticsUrl + `/auto-commissioning/tests/${testId}`, this.jsonTypeHeaders).pipe(catchError(this.handleError));
    }

    // BLOCKLY HOOKS
    waitHook(block: Blockly.Block) {
        let value_duration = (Blockly as any).JavaScript.valueToCode(block, 'Wait', (Blockly as any).JavaScript.ORDER_ATOMIC);
        let value_unit = block.getFieldValue('Unit');
        let code = `
        GLOBAL_TOTAL_DELAY += toMinutes(${value_duration}, "${value_unit}"); 
        `;
        return code;
    }

    systemHeatingLoopOverrideHook(block: Blockly.Block) {
        let hlo = (Blockly as any).JavaScript.valueToCode(block, 'System Heating Loop', (Blockly as any).JavaScript.ORDER_ATOMIC);
        let flo = (Blockly as any).JavaScript.valueToCode(block, 'System Fan Loop', (Blockly as any).JavaScript.ORDER_ATOMIC);
        let value_duration = (Blockly as any).JavaScript.valueToCode(block, 'Duration', (Blockly as any).JavaScript.ORDER_ATOMIC);
        let value_unit = block.getFieldValue('Unit');

        let code = `
        GLOBAL_SYSTEM_COMMAND_DURATION += toMinutes(${value_duration}, "${value_unit}"); 
        GLOBAL_MAX_COMMAND_DURATION = Math.max(GLOBAL_MAX_COMMAND_DURATION, toMinutes(${value_duration}, "${value_unit}"));
        GLOBAL_IS_SYSTEM_OVERRIDE = true;

        if (${flo} == null || ${flo} == 0) {
            GLOBAL_IS_FAN_LOOP_ENABLED = false;
        }
        `;
        return code;
    }

    systemCoolingLoopOverrideHook(block: Blockly.Block) {
        let clo = (Blockly as any).JavaScript.valueToCode(block, 'System Cooling Loop', (Blockly as any).JavaScript.ORDER_ATOMIC);
        let flo = (Blockly as any).JavaScript.valueToCode(block, 'System Fan Loop', (Blockly as any).JavaScript.ORDER_ATOMIC);
        let value_duration = (Blockly as any).JavaScript.valueToCode(block, 'Duration', (Blockly as any).JavaScript.ORDER_ATOMIC);
        let value_unit = block.getFieldValue('Unit');

        let code = `
        GLOBAL_SYSTEM_COMMAND_DURATION += toMinutes(${value_duration}, "${value_unit}"); 
        GLOBAL_MAX_COMMAND_DURATION = Math.max(GLOBAL_MAX_COMMAND_DURATION, toMinutes(${value_duration}, "${value_unit}"));
        GLOBAL_IS_SYSTEM_OVERRIDE = true;

        if (${flo} == null || ${flo} == 0) {
            GLOBAL_IS_FAN_LOOP_ENABLED = false;
        }
        `;
        return code;
    }

    systemFanLoopOverrideHook(block: Blockly.Block) {
        let flo = (Blockly as any).JavaScript.valueToCode(block, 'System Fan Loop', (Blockly as any).JavaScript.ORDER_ATOMIC);
        let value_duration = (Blockly as any).JavaScript.valueToCode(block, 'Duration', (Blockly as any).JavaScript.ORDER_ATOMIC);
        let value_unit = block.getFieldValue('Unit');

        let code = `
        GLOBAL_SYSTEM_COMMAND_DURATION += toMinutes(${value_duration}, "${value_unit}"); 
        GLOBAL_MAX_COMMAND_DURATION = Math.max(GLOBAL_MAX_COMMAND_DURATION, toMinutes(${value_duration}, "${value_unit}"));
        GLOBAL_IS_SYSTEM_OVERRIDE = true;
        
        if (${flo} == null || ${flo} == 0) {
            GLOBAL_IS_FAN_LOOP_ENABLED = false;
        }
        `;
        return code;
    }

    tunerOverrideHook(block: Blockly.Block) {
        let value_query = (Blockly as any).JavaScript.valueToCode(block, 'Tuner Query', (Blockly as any).JavaScript.ORDER_ATOMIC);
        let value_override = (Blockly as any).JavaScript.valueToCode(block, 'Value', (Blockly as any).JavaScript.ORDER_ATOMIC);
        
        let code = `
        if (toTags(${value_query}).includes("temp") && ( ${value_override} < MINIMUM_TEMP || ${value_override} > MAXIMUM_TEMP ) ) {
            GLOBAL_IS_TEMP_OUT_OF_BOUNDS = true;
        }
        `;
        return code;
    }

    zoneHeatingLoopOverrideHook(block: Blockly.Block) {
        let hlo = (Blockly as any).JavaScript.valueToCode(block, 'System Heating Loop', (Blockly as any).JavaScript.ORDER_ATOMIC);
        let flo = (Blockly as any).JavaScript.valueToCode(block, 'System Fan Loop', (Blockly as any).JavaScript.ORDER_ATOMIC);
        let systemLoad = (Blockly as any).JavaScript.valueToCode(block, 'System Load (%)', (Blockly as any).JavaScript.ORDER_ATOMIC);

        let value_duration = (Blockly as any).JavaScript.valueToCode(block, 'Duration', (Blockly as any).JavaScript.ORDER_ATOMIC);
        let value_unit = block.getFieldValue('Unit');

        let code = `
        `;
        return code;
    }

    zoneCoolingLoopOverrideHook(block: Blockly.Block) {
        let clo = (Blockly as any).JavaScript.valueToCode(block, 'Zone Cooling Loop', (Blockly as any).JavaScript.ORDER_ATOMIC);
        let flo = (Blockly as any).JavaScript.valueToCode(block, 'Zone Fan Loop', (Blockly as any).JavaScript.ORDER_ATOMIC);
        let systemLoad = (Blockly as any).JavaScript.valueToCode(block, 'System Load (%)', (Blockly as any).JavaScript.ORDER_ATOMIC);

        let value_duration = (Blockly as any).JavaScript.valueToCode(block, 'Duration', (Blockly as any).JavaScript.ORDER_ATOMIC);
        let value_unit = block.getFieldValue('Unit');

        let code = `
        `;
        return code;
    }

    zoneFanLoopOverrideHook(block: Blockly.Block) {
        let flo = (Blockly as any).JavaScript.valueToCode(block, 'Zone Fan Loop', (Blockly as any).JavaScript.ORDER_ATOMIC);
        let systemLoad = (Blockly as any).JavaScript.valueToCode(block, 'System Load (%)', (Blockly as any).JavaScript.ORDER_ATOMIC);

        let value_duration = (Blockly as any).JavaScript.valueToCode(block, 'Duration', (Blockly as any).JavaScript.ORDER_ATOMIC);
        let value_unit = block.getFieldValue('Unit');

        let code = `
        `;
        return code;
    }

    zoneDamperOverrideHook(block: Blockly.Block) {
        let damperPos = (Blockly as any).JavaScript.valueToCode(block, 'Zone Damper Loop', (Blockly as any).JavaScript.ORDER_ATOMIC);
        let systemLoad = (Blockly as any).JavaScript.valueToCode(block, 'System Load (%)', (Blockly as any).JavaScript.ORDER_ATOMIC);

        let value_duration = (Blockly as any).JavaScript.valueToCode(block, 'Duration', (Blockly as any).JavaScript.ORDER_ATOMIC);
        let value_unit = block.getFieldValue('Unit');

        let code = `
        `;
        return code;
    }

    genericOverrideHook(block: Blockly.Block) {
        let value_query = (Blockly as any).JavaScript.valueToCode(block, 'Query', (Blockly as any).JavaScript.ORDER_ATOMIC);
        let value_override = (Blockly as any).JavaScript.valueToCode(block, 'Value', (Blockly as any).JavaScript.ORDER_ATOMIC);

        let code = `
        if (toTags(${value_query}).includes("system")) {
            if (toTags(${value_query}).every(tag => ["fan", "loop", "output"]) && ${value_override} == 0 ) {
                GLOBAL_IS_FAN_LOOP_ENABLED = false;
            }
        }

        if (toTags(${value_query}).includes("temp") && ( ${value_override} < MINIMUM_TEMP || ${value_override} > MAXIMUM_TEMP ) ) {
            GLOBAL_IS_TEMP_OUT_OF_BOUNDS = true;
        }
        `;
        return code;
    }
}
