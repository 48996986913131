import { Renderer2, ViewEncapsulation, Component, ElementRef, OnInit, ViewChild, OnDestroy, ChangeDetectorRef, Inject, Optional } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AutoCxService, FlowControlBlock } from '../../services/auto-cx-service';

import Drawflow, { DrawFlowEditorMode } from 'drawflow';
import { ToastService } from 'src/app/shared/services/toast.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { BaseWrapperDirective } from 'src/app/shared/classes/base-wrapper';
import { Router } from '@angular/router';
import { FlowStatistics } from '../../models/FlowStatistics.model';
import { debounceTime, merge, take, tap } from 'rxjs';
import { Helper } from 'src/app/shared/helpers/helper';
import { StorageService } from 'src/app/shared/services/storage.service';
import { AutoCommissioningBlock, AutoCommissioningFlow, AutoCommissioningNode } from '../../models/AutoCommissioning.model';
import { TestCategory } from '../../enums/TestCategory.enum';

export enum ElementMode {
    BLOCKS = "blocks",
    FLOWS = "flows"
}

@Component({
    selector: 'app-auto-cx-builder',
    templateUrl: './auto-cx-builder.component.html',
    styleUrls: ['./auto-cx-builder.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AutoCxBuilderComponent extends BaseWrapperDirective implements OnInit, OnDestroy {
    flowManagementGroup: FormGroup;
    publicBlockPanel = true;
    privateBlockPanel = true;
    subscriptions: any = {};
    blockSearchText: string = "";
    editor: Drawflow;
    mobile_item_selec: string = '';
    mobile_last_move: any = null;
    transform: string = '';

    activeModule: any = "Home";
    nodeBlockLookup: any = {};

    // Store current session
    activeNodeId: string;

    flowStatistics: FlowStatistics = null;
    flowErrors: any = [];

    blockPanelActive: boolean = false;

    // DrawFlow Container
    @ViewChild('drawflowContainer', { read: ElementRef }) drawflowContainer: ElementRef;

    @ViewChild('stepper') stepper;

    constructor(
        private readonly render: Renderer2,
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly router: Router,
        private readonly formBuilder: FormBuilder,
        private readonly helper: Helper,
        private readonly storage: StorageService,
        private readonly autoCxService: AutoCxService,
        private readonly toastService: ToastService,
        private readonly dialogService: DialogService,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        super();
    }

    activeFlow?: AutoCommissioningFlow;
    flowSelect: UntypedFormControl = new UntypedFormControl();
    flowSearch: UntypedFormControl = new UntypedFormControl('');
    viewMode: boolean = false;
    editActive: boolean = false;

    ngOnInit() {
        this.buildForms();
        this.filteredFlows$ = this.autoCxService.savedFlows;

        this.subs.sink = this.flowSearch.valueChanges.pipe(
            debounceTime(300),
            tap((value) => this.filterFlows(value))
        ).subscribe();

        this.subs.sink = this.flowSelect.valueChanges.pipe(
            tap((flow) => {
                this.activeFlow = flow;
                this.addFlowToWorkspace(flow);
            }),
        ).subscribe();

        this.subs.sink = this.autoCxService.flowsLoaded$.pipe(
            tap(() => this.filteredFlows$ = structuredClone(this.autoCxService.savedFlows)),
            tap(() => this.flowSelect.setValue(this.activeFlow))
        ).subscribe();
    }

    get savedFlows$() {
        return this.autoCxService.savedFlows;
    }

    filteredFlows$: any = {};
    filterFlows(value: any) {
        if (this.helper.isBlank(value)) {
            this.filteredFlows$ = structuredClone(this.savedFlows$);
        } else {
            value = value.toLowerCase();
            this.filteredFlows$.publishedFlows = this.savedFlows$.publishedFlows.filter(flow => flow.name.toLowerCase().includes(value));
            this.filteredFlows$.privateFlows = this.savedFlows$.privateFlows.filter(flow => flow.name.toLowerCase().includes(value));
        }
    }

    goToTestsUI() {
        this.router.navigate(['/tests']);
    }

    get isViewMode() {
        if (this.data?.viewMode) {
            return true;
        }
        return false;
    }

    ngAfterViewInit() {
        /* Mouse and Touch Actions */
        const draggableElements: any = document.querySelectorAll('.drag-drawflow');

        draggableElements.forEach(element => {
            this.render.listen(element, 'touchstart', this.drag);
            this.render.listen(element, 'touchmove', this.positionMobile);
            this.render.listen(element, 'touchend', this.drop);
        });

        this.initializeDrawflow();

        // If a flowId is specified, open its contents
        if (this.data?.flowId) {
            let flow = this.savedFlows$.publishedFlows.find(f => f.flowId == this.data.flowId);
            this.activeFlow = flow;
            this.addFlowToWorkspace(flow);
        }
    }

    buildForms() {
        this.flowManagementGroup = this.formBuilder.group({
            flowName: new FormControl({ value: null, disabled: true }),
            flowDescription: new FormControl({ value: null, disabled: true })
        });
    }

    get flowName() {
        return this.flowManagementGroup?.get("flowName");
    }

    get flowDescription() {
        return this.flowManagementGroup?.get("flowDescription");
    }

    builderLibraryTabIndex: number = 0;
    changeTabTo(mode: ElementMode) {
        if (mode == ElementMode.BLOCKS) this.builderLibraryTabIndex = 0;
        else this.builderLibraryTabIndex = 1;
    }

    get savedBlocks$() {
        return this.autoCxService.savedBlocks;
    }

    flowRepositioned: boolean = false;
    initializeDrawflow(): void {

        try {
            const { nativeElement } = this.drawflowContainer;
            if (!!nativeElement) {
                this.editor = new Drawflow(nativeElement);
                this.editor.reroute = true;
                this.editor.reroute_fix_curvature = true;
                this.editor.force_first_input = false;
                this.editor['useuuid'] = true;

                this.editor.start();

                this.editor.on('nodeSelected', (id) => this.activeNodeId = id.toString())
                this.editor.on('nodeUnselected', (_) => this.activeNodeId = null)

                this.editor.on('nodeCreated', (id) => {
                    
                })
                this.editor.on('nodeRemoved', (id) => {
                    this.updateActiveFlow();
                    this.editActive = true;
                })
                this.editor.on('nodeMoved', () => {
                    this.flowRepositioned = true;
                })
                this.editor.on('connectionCreated', (id) => {
                    this.updateActiveFlow();
                    this.editActive = true;
                })
                this.editor.on('connectionRemoved', (id) => {
                    this.updateActiveFlow();
                    this.editActive = true;
                })
                this.editor.on('clickEnd', (e) => {
                    if (e.detail === 2 && this.isAutoCxNode(e)) {
                        setTimeout(() => this.onViewOrEditNode(), 200);
                    }
                })
            } else {
                console.error('Drawflow host element does not exist');
            }
        } catch (exception) {
            console.error('Unable to start Drawflow', exception);
        }
    }

    private updateActiveFlow() {
        this.flowManagementGroup.markAsTouched();
        this.flowName.enable();
        this.flowDescription.enable();
        this.activeFlow = this.buildFlow();
        this.updateFlowStatistics();
    }
    
    private isAutoCxNode(element: any) {
        return element.target?.classList?.contains('auto-cx-node') || element.target?.parentElement?.classList?.contains('auto-cx-node');
    }

    /**
     * A block can only be modified if the Flows containing it are not associated with any Test (scheduled, active or completed).
     * @param block 
     */
    confirmEditBlock(block: any) {
        const flows = this.savedFlows$.privateFlows.concat(this.savedFlows$.publishedFlows).filter(f => f.nodes.map(n => n.opCode).includes(block.opCode));
        const flowIds = flows.map(f => f.flowId);
        if (flowIds.length > 0) {
            this.autoCxService.getTestsForFlows(flowIds).subscribe((tests) => {
                if (tests.length > 0) {
                    this.dialogService.confirmation(
                        `Edit Block ${block.name}`,
                        `This block cannot be modified. It is associated with ${tests.length} Test(s)`,
                        [{ name: 'Tests', items: this._mapTests(tests) }],
                        true,
                        null,
                        "OK",
                        "30%",
                        "40%"
                    );
                } else {
                    this.dialogService.confirmation(
                        `Edit Block ${block.name}`,
                        `Are you sure you want to modify this block? It is associated with ${flows.length} Flow(s)`,
                        [{ name: 'Flows', items: this._mapFlows(flows) }],
                        false,
                        "Yes",
                        "No",
                        "30%",
                        "40%"
                    );
                    this.subs.sink = this.autoCxService.confirm.pipe(
                        take(1),
                        tap((confirm) => {
                            if (confirm) {
                                this.openBlocklyDialog(block, false, true);
                            }
                        })
                    ).subscribe();
                }
            })
        } else {
            this.openBlocklyDialog(block, false, true);
        }
    }

    /**
     * A block can only be removed if no flows or tests are associated with it.
     * @param block 
     */
    confirmRemoveBlock(block: any) {
        const flows = this.savedFlows$.privateFlows.concat(this.savedFlows$.publishedFlows).filter(f => f.nodes.map(n => n.opCode).includes(block.opCode));
        const flowIds = flows.map(f => f.flowId);
        if (flowIds.length > 0) {
            this.autoCxService.getTestsForFlows(flowIds).subscribe((tests) => {
                if (tests.length > 0) {
                    this.dialogService.confirmation(
                        `Delete Block ${block.name}`,
                        `This block cannot be deleted. It is associated with ${flows.length} Flow(s) and ${tests.length} Test(s)`,
                        [{ name: 'Flows', items: this._mapFlows(flows) }, { name: 'Tests', items: this._mapTests(tests) }],
                        true,
                        null,
                        "OK",
                        "30%",
                        "40%"
                    );
                } else {
                    this.dialogService.confirmation(
                        `Delete Block ${block.name}`,
                        `This block cannot be deleted. It is associated with ${flows.length} Flow(s)`,
                        [{ name: 'Flows', items: this._mapFlows(flows) }],
                        true,
                        null,
                        "OK",
                        "30%",
                        "40%"
                    );
                }
            })
        } else {
            this.dialogService.confirmation(
                `Delete Block ${block.name}`, 
                 "Are you sure you want to delete this block?",
                 [],
                 false,
                 "Yes",
                 "No",
                 "150px",
                 "30%"
             );
             this.subs.sink = this.autoCxService.confirm.pipe(
                 take(1),
                 tap((confirmed) => {
                     if (confirmed) {
                         this.autoCxService.removeBlock(block.opCode).subscribe((res) => {
                             this.toastService.success("Successfully removed block!");
                             this.autoCxService.loadBlockLibrary.next();
                             this.reset();
                         }, (error) => {
                             this.toastService.error({ message: "Failed to remove block" });
                             console.error(error);
                         })
                     }
                 })
             ).subscribe();
        }
    }

    /**
     * Opens a modal UI to view or edit a Blockly element
     * @param nodeId Id of the node
     */
    openBlocklyDialog(block: AutoCommissioningBlock, newBlock: boolean, editMode: boolean, node?: AutoCommissioningNode) {
        this.dialogService.openBlockBuilder(block, newBlock, editMode, node);
        this.autoCxService.loadBlockLibrary.next();
    }

    get canViewOrEdit() {
        let opCode = this.nodeBlockLookup[this.activeNodeId]?.opCode;
        if (opCode == FlowControlBlock.START || opCode == FlowControlBlock.STOP) 
            return false;
        return true;
    }

    /**
     * Opens a node for editing or viewing
     * - A published block can only be viewed, but you can create a new block from it
     * - An unpublished block can be edited but may not have blockly-data if it's empty (e.g. new custom block)
     */
    onViewOrEditNode() {
        const flowNode = this.activeFlow?.nodes.find(n => n.nodeId == this.activeNodeId);
        const blockDto = this.nodeBlockLookup[this.activeNodeId];
        if (blockDto) {
            let block = this.getBlock(blockDto.opCode);
            if (block.opCode != FlowControlBlock.START && block.opCode != FlowControlBlock.STOP) {
                this.openBlocklyDialog(block, false, !block.isPublished, flowNode);
            }
        }
    }

    /**
     * Clears the elements from the workspace, but maintains the state of an active flow
     */
    clearWorkspace() {
        this.editor.clearModuleSelected();
        this.activeNodeId = null;
        this.flowStatistics = null;
    }

    /**
     * Resets the entire state of the workspace, including the elements and the active flow
     */
    reset() {
        this.editor.clearModuleSelected();
        this.flowManagementGroup.reset();
        this.activeFlow = null;
        this.activeNodeId = null;
        this.flowStatistics = null;
    }

    positionMobile(ev) {
        this.mobile_last_move = ev;
    }

    allowDrop(ev) {
        ev.preventDefault();
    }

    get panelState() {
        if (this.publicBlockPanel && this.privateBlockPanel) {
            return true;
        }
        else {
            return false;
        }
    }

    drag(ev) {
        this.changeDetectorRef.detach();
        if (ev.type === "touchstart") {
            this.mobile_item_selec = ev.target.closest(".drag-drawflow").getAttribute("data-blocks");
        } else {
            ev.dataTransfer.setData("node", ev.target.getAttribute("data-blocks"));
        }
    }

    drop(ev) {
        this.changeDetectorRef.reattach();
        if (ev.type === "touchend") {
            let parentdrawflow = document.elementFromPoint(this.mobile_last_move.touches[0].clientX, this.mobile_last_move.touches[0].clientY).closest("#drawflow");
            if (parentdrawflow != null) {
                this.addBlockToDrawFlow(this.mobile_item_selec, this.mobile_last_move.touches[0].clientX, this.mobile_last_move.touches[0].clientY);
            }
            this.mobile_item_selec = '';
        } else {
            ev.preventDefault();
            let opCode = ev.dataTransfer.getData("node");
            this.addBlockToDrawFlow(opCode, ev.clientX, ev.clientY);
            if (!this.activeFlow) {
                this.createNewFlow(false);
            }
            this.editActive = true;
        }
    }

    get isWorkspaceCleared(): boolean {
        const moduleData = this.editor?.drawflow?.drawflow[this.activeModule]?.data;
        return moduleData ? Object.keys(moduleData).length === 0 : false;
    }

    addBlockToDrawFlow(opCode, pos_x, pos_y) {
        if (this.editor.editor_mode === 'fixed') {
            return false;
        }
        pos_x = pos_x * (this.editor['precanvas'].clientWidth / (this.editor['precanvas'].clientWidth * this.editor.zoom)) - (this.editor['precanvas'].getBoundingClientRect().x * (this.editor['precanvas'].clientWidth / (this.editor['precanvas'].clientWidth * this.editor.zoom)));
        pos_y = pos_y * (this.editor['precanvas'].clientHeight / (this.editor['precanvas'].clientHeight * this.editor.zoom)) - (this.editor['precanvas'].getBoundingClientRect().y * (this.editor['precanvas'].clientHeight / (this.editor['precanvas'].clientHeight * this.editor.zoom)));

        let block = this.getBlock(opCode);
        if (block) {
            block = JSON.parse(JSON.stringify(this.getBlock(opCode)));
            block.xml = block.executionData.xml;
            block.data = { name: block.name };
            block.dfHtml = `<div class='auto-cx-node'><i style="color: ${block.presentationData.color}" class="${block.presentationData.icon}"></i><span class="block-name">${block.name}</span></div>`;
            let newNodeId = this.editor.addNode(block.name, block.numInputs, block.numOutputs, pos_x, pos_y, block.opCode, block.data, block.dfHtml, false);
            this.nodeBlockLookup[newNodeId] = block;
            this.flowManagementGroup.markAsDirty();
        }
    }

    setFlowEditState(editState: boolean) {
        if (editState) {
            /**
             * A flow can only be modified if it has no associated tests.
             */
            if (this.activeFlow.isPublished) {
                this.autoCxService.getTestsForFlows([this.activeFlow.flowId]).subscribe((tests) => {
                    if (tests.length > 0) {
                        this.dialogService.confirmation(
                           `Edit Published Flow ${this.activeFlow.name}`, 
                            `This flow cannot be modified. It is associated with ${tests.length} test(s).`, 
                            [{ name: 'Tests', items: this._mapTests(tests) }],
                            true,
                            null,
                            "OK",
                            "30%",
                            "40%"
                        );
                    } else {
                        this.dialogService.confirmation(
                           `Edit Published Flow ${this.activeFlow.name}`, 
                            "Are you sure you want to modify this flow?",
                            [],
                            false,
                            "Yes",
                            "No",
                            "30%",
                            "30%"
                        );
                        this.subs.sink = this.autoCxService.confirm.pipe(
                            take(1),
                            tap((confirmed) => {
                                if (confirmed) {
                                    this.editActive = true;
                                    this.flowManagementGroup.controls['flowName'].enable();
                                    this.flowManagementGroup.controls['flowDescription'].enable();
                                    this.openBlockPanel();
                                }
                            })
                        ).subscribe();
                    }
                })
            } else {
                this.editActive = true;
                this.flowManagementGroup.controls['flowName'].enable();
                this.flowManagementGroup.controls['flowDescription'].enable();
                this.openBlockPanel();
            }
        } else {
            if (this.hasUnsavedChanges) {
                this.dialogService.confirmation(
                    "Discard Changes", 
                    "Your changes won't be saved, do you still want to continue?", 
                    [],
                    false,
                    "Yes",
                    "No"
                );
                this.subs.sink = this.autoCxService.confirm.pipe(
                    take(1),
                    tap((discard) => {
                        if (discard) {
                            if (this.activeFlow?.flowId == null) {
                                // If an unsaved flow was discarded, reset workspace
                                this.reset();
                            } else {
                                // If an existing flow's modifications are discarded, load saved flow
                                const flow = this.getFlow(this.activeFlow.flowId);
                                if (flow) {
                                    this.addFlowToWorkspace(flow);
                                    this.flowManagementGroup.controls['flowName'].setValue(this.activeFlow.name);
                                    this.flowManagementGroup.controls['flowDescription'].setValue(this.activeFlow.description);
                                }
                            }
                            this.closeBlockPanel();
                            this.editActive = false;
                        }
                    })
                ).subscribe();
            }
            else {
                this.flowManagementGroup.controls['flowName'].disable();
                this.flowManagementGroup.controls['flowDescription'].disable();
                this.closeBlockPanel();
                this.editActive = false;
            }
        }
    }

    createNewFlow(reset: boolean) {
        if (reset) this.reset();
        this.editActive = true;
        this.flowManagementGroup.controls['flowName'].enable();
        this.flowManagementGroup.controls['flowDescription'].enable();
        this.flowManagementGroup.controls['flowName'].markAsTouched();
        this.flowManagementGroup.controls['flowDescription'].markAsTouched();
        this.openBlockPanel();
        this.activeFlow = this.buildFlow();
    }

    copyFlow() {
        let newFlow = JSON.parse(JSON.stringify(this.activeFlow));
        newFlow.flowId = null;
        newFlow.name = this.generateNewFlowNameFromExisting(newFlow);
        newFlow.isPublished = false;
        this.flowManagementGroup.controls['flowName'].enable();
        this.flowManagementGroup.controls['flowDescription'].enable();
        this.flowManagementGroup.controls['flowName'].setValue(newFlow.name);
        this.flowManagementGroup.controls['flowDescription'].setValue(newFlow.description);
        this.flowManagementGroup.markAsDirty();
        this.activeFlow = newFlow;

        this.openBlockPanel();
        this.editActive = true;
    }

    get hasUnsavedChanges() {
        return this.isFlowChanged;
    }

    addFlowToWorkspace(flow) {
        this.editor.clear();

        if (flow) {
            const dfObject = this.generateDFObjectFromFlow(flow);
            this.editor.import(dfObject);

            this.flowManagementGroup.controls['flowName'].setValue(flow.name);
            this.flowManagementGroup.controls['flowDescription'].setValue(flow.description);
            if (flow.isPublished) {
                this.flowManagementGroup.controls['flowName'].disable();
                this.flowManagementGroup.controls['flowDescription'].disable();
            } else {
                this.flowManagementGroup.controls['flowName'].enable();
                this.flowManagementGroup.controls['flowDescription'].enable();
            }

            this.updateFlowStatistics();
            this.activeNodeId = null;
            this.flowManagementGroup.markAsPristine();
            this.flowRepositioned = false;
        }
    }

    getBlock(opCode: string): AutoCommissioningBlock {
        return this.autoCxService.getBlock(opCode);
    }

    /**
     * Create a new DrawFlow object from the current workspace
     * @param flow: AutoCommissioningFlow
     * @returns DrawFlow object
     */
    generateDFObjectFromFlow(flow: AutoCommissioningFlow) {
        let DF = {
            drawflow: {
                Home: {
                    data: {}
                }
            }
        };

        flow.nodes.forEach((node: AutoCommissioningNode) => {
            const opCode = node.opCode;
            let block = this.getBlock(opCode);
            if (block) {
                block = JSON.parse(JSON.stringify(block));
                block.xml = block.executionData.xml;
                block.data = { name: block.name };
                block.dfHtml = `<div class='auto-cx-node'><i style="color: ${block.presentationData.color}" class="${block.presentationData.icon}"></i><span class="block-name">${block.name}</span></div>`;
                DF.drawflow["Home"].data[node.nodeId] = {
                    id: node.nodeId,
                    name: block.name,
                    data: { name: block.name },
                    class: opCode,
                    html: block.dfHtml,
                    inputs: this.mapInputs(node.inputs),
                    outputs: this.mapOutputs(node.outputs),
                    pos_x: node.presentationData.positionX,
                    pos_y: node.presentationData.positionY,
                    ruleSetId: node.ruleSetId,
                    typenode: false
                }

                this.nodeBlockLookup[node.nodeId] = block;
            }
        })
        return DF;
    }

    mapInputs(nodeInputs) {
        let dfInputs = {};
        nodeInputs.forEach((nodeInput) => {
            dfInputs[nodeInput.inputId] = {
                connections: nodeInput.connections.map((c) => {
                    return { "node": c.nodeId, "input": c.input }
                })
            }
        })
        return dfInputs;
    }

    mapOutputs(nodeOutputs) {
        let dfInputs = {};
        nodeOutputs.forEach((nodeOutput) => {
            dfInputs[nodeOutput.outputId] = {
                connections: nodeOutput.connections.map((c) => {
                    return { "node": c.nodeId, "output": c.output }
                })
            }
        })
        return dfInputs;
    }

    copyBlock(opCode: string) {
        let newBlock = JSON.parse(JSON.stringify(this.getBlock(opCode)));
        newBlock.name = `${newBlock.name}(${this.savedBlocks$.privateBlocks.length + 1})`;
        newBlock.isPublished = false;
        newBlock.opCode = null;
        this.subs.sink = this.autoCxService.createBlock(newBlock).subscribe((res) => {
            this.toastService.success("Successfully copied block!")
            this.autoCxService.loadBlockLibrary.next();
        }, (error) => {
            this.toastService.error({ message: "Failed to copy block" });
            console.error(error);
        })
    }

    publishBlock(opCode: string) {
        let updatedBlock = structuredClone(this.getBlock(opCode));
        updatedBlock.isPublished = true;
        this.subs.sink = this.autoCxService.updateBlock(updatedBlock).subscribe((_) => {
            this.toastService.success("Successfully published block to public library.");
            this.autoCxService.loadBlockLibrary.next();
        }, (error) => {
            this.toastService.error({ message: "Failed to publish block" });
            console.error(error);
        })
    }

    removeBlock(opCode: string) {
        this.subs.sink = this.autoCxService.removeBlock(opCode).subscribe((res) => {
            this.toastService.success("Successfully removed block!");
            this.autoCxService.loadBlockLibrary.next();
        }, (error) => {
            this.toastService.error({ message: "Failed to removed block" });
            console.error(error);
        })
    }

    getFlow(flowId: string) {
        return (this.savedFlows$.publishedFlows.find(f => f.flowId == flowId) || this.savedFlows$.privateFlows.find(f => f.flowId == flowId));
    }

    updateFlowStatistics() {
        const flow = this.buildFlow();
        let errors = [];
        let opCodes = flow.nodes.map(n => n.opCode);

        if (opCodes.includes(FlowControlBlock.START) && opCodes.includes(FlowControlBlock.STOP)) {
            this.flowStatistics = this.autoCxService.getFlowStatistics(flow);
            if (!this.flowStatistics.isFanEnabledForAllSystemLevelBlocks) {
                errors.push({
                    message: "Fan loop must be enabled for system-level tests",
                    type: "ERROR"
                })
            }
            if (this.flowStatistics.isTempOutOfBounds) {
                errors.push({
                    message: "Temperature Setpoints must be within 50 and 120 °F",
                    type: "ERROR"
                })
            }
        }
        this.flowErrors = [...errors];
    }

    /**
     * Publish a flow.
     * If the flow contains private readonly blocks, also publish this blocks.
     */
    publishFlow() {
        let privateBlocks = [];
        this.activeFlow.nodes.forEach((node) => {
            let block = this.savedBlocks$.privateBlocks.find(block => block.opCode == node.opCode);
            if (block != null) {
                privateBlocks.push(structuredClone(block));
            }
        });
        if (privateBlocks.length == 0) {
            let flow = structuredClone(this.activeFlow);
            flow.isPublished = true;
            this.autoCxService.updateFlow(flow).pipe(
                tap((updatedFlow) => {
                    this.toastService.success("Successfully published flow to public library. It is now available for testing.");
                    this.autoCxService.loadFlowLibrary.next();
                    this.activeFlow = updatedFlow;
                })
            ).subscribe();
        } else {
            merge(...privateBlocks.map(block => {
                block.isPublished = true;
                return this.autoCxService.updateBlock(block)
            })).pipe(
                tap(() => {
                    let flow = structuredClone(this.activeFlow);
                    flow.isPublished = true;
                    this.autoCxService.updateFlow(flow).pipe(
                        tap((updatedFlow) => {
                            this.toastService.success("Successfully published flow to public library. It is now available for testing.");
                            this.autoCxService.loadBlockLibrary.next();
                            this.autoCxService.loadFlowLibrary.next();
                            this.activeFlow = updatedFlow;
                        })
                    ).subscribe();
                })
            ).subscribe();
        }
    }

    removeFlow() {
        if (this.activeFlow.isPublished) {
            this.autoCxService.getTestsForFlows([this.activeFlow.flowId]).subscribe((tests) => {
                if (tests.length > 0) {
                    this.dialogService.confirmation(
                       `Delete Published Flow ${this.activeFlow.name}`, 
                        `This flow cannot be deleted. It is associated with ${tests.length} test(s).`, 
                        [{ name: 'Tests', items: this._mapTests(tests) }],
                        true,
                        null,
                        "OK",
                        "30%",
                        "40%"
                    );
                } else {
                    this.dialogService.confirmation(
                       `Delete Published Flow ${this.activeFlow.name}`, 
                        "Are you sure you want to delete this flow?",
                        [],
                        false,
                        "Yes",
                        "No",
                        "150px",
                        "30%"
                    );
                    this.subs.sink = this.autoCxService.confirm.pipe(
                        take(1),
                        tap((confirmed) => {
                            if (confirmed) {
                                this.autoCxService.removeFlow(this.activeFlow.flowId).subscribe((res) => {
                                    this.toastService.success("Successfully removed flow!");
                                    this.autoCxService.loadFlowLibrary.next();
                                    this.reset();
                                }, (error) => {
                                    this.toastService.error({ message: "Failed to remove flow" });
                                    console.error(error);
                                })
                            }
                        })
                    ).subscribe();
                }
            })
        } else {
            this.subs.sink = this.autoCxService.removeFlow(this.activeFlow.flowId).subscribe((res) => {
                this.toastService.success("Successfully removed flow!");
                this.autoCxService.loadFlowLibrary.next();
                this.reset();
            }, (error) => {
                this.toastService.error({ message: "Failed to remove flow" });
                console.error(error);
            })    
        }
    }

    /**
     * Generates flow object from Drawflow UI
     * @returns flow object
     */
    buildFlow(): AutoCommissioningFlow {
        const flow: AutoCommissioningFlow = {
            flowId: this.activeFlow?.flowId,
            name: this.flowManagementGroup.get('flowName').value,
            category: TestCategory.COMMISSIONING,
            description: this.flowManagementGroup.get('flowDescription').value,
            isPublished: this.activeFlow?.isPublished ?? false,
            nodes: []
        };

        // get active drawflow data
        let drawflowData = this.editor.drawflow.drawflow[this.activeModule];

        Object.keys(drawflowData.data).forEach((nodeId) => {
            let dfNode = JSON.parse(JSON.stringify(drawflowData.data[nodeId]));
            let opCode = dfNode.class;
            let flowNode = {
                nodeId: nodeId,
                opCode: opCode,
                isPublished: false,
                inputs: [],
                outputs: [],
                presentationData: {
                    positionX: dfNode.pos_x,
                    positionY: dfNode.pos_y
                },
                ruleSetId: dfNode.ruleSetId
            };

            let inputs = [];
            let outputs = [];

            Object.keys(dfNode.inputs).forEach((inputId) => {
                let connections = dfNode.inputs[inputId].connections;
                if (connections.length) {
                    inputs.push(
                        {
                            inputId: inputId,
                            connections: connections.map(conn => ({
                                'nodeId': conn.node,
                                'input': conn.input
                            }))
                        }
                    )
                }
            });
            Object.keys(dfNode.outputs).forEach((outputId) => {
                let connections = dfNode.outputs[outputId].connections;
                if (connections.length) {
                    outputs.push(
                        {
                            outputId: outputId,
                            connections: connections.map(conn => ({
                                'nodeId': conn.node,
                                'output': conn.output
                            }))
                        }
                    )
                }
            });

            flowNode.inputs = inputs;
            flowNode.outputs = outputs;
            flow.nodes.push(flowNode);
        });
        return flow;
    }

    get isFlowPublished() {
        return this.activeFlow?.isPublished ?? false;
    }

    get isFlowCreatedByUser() {
        return this.activeFlow?.createdBy === this.storage.userId;
    }

    get isFlowValid() {
        return !this.flowName?.invalid && !this.flowDescription?.invalid && [FlowControlBlock.START, FlowControlBlock.STOP].every(opCode => this.activeFlow?.nodes.map(n => n.opCode).includes(opCode));
    }

    get isFlowChanged() {
        return this.flowRepositioned || this.activeFlow?.name != this.flowManagementGroup?.get('flowName')?.value || 
        this.activeFlow?.description != this.flowManagementGroup?.get('flowDescription')?.value || 
        this.flowManagementGroup.dirty;
    }

    get isSaveAllowed() {
        return this.activeFlow && !this.isFlowPublished && this.isFlowChanged && this.editActive && this.isFlowValid;
    }

    /**
     * If an active flow exists, update its contents.
     */
    saveFlow() {
        const flow = this.buildFlow();
        if (flow.flowId == null) {
            this.subs.sink = this.autoCxService.createFlow(flow).subscribe((savedFlow) => {
                this.toastService.success("Successfully created flow!");
                this.doOnPostSave(savedFlow);
            }, (error) => {
                this.toastService.error({ message: "Failed to create flow" });
                console.error(error);
            })
        } else {
            this.subs.sink = this.autoCxService.updateFlow(flow).subscribe((updatedFlow) => {
                this.toastService.success("Successfully updated flow!");
                this.doOnPostSave(updatedFlow);
            }, (error) => {
                this.toastService.error({ message: "Failed to update flow" });
                console.error(error);
            })
        }
    }

    doOnPostSave(flow) {
        this.editActive = false;
        this.activeFlow = flow;
        this.autoCxService.loadFlowLibrary.next();
        this.closeBlockPanel();
    }

    setEditorMode(mode: DrawFlowEditorMode) {
        this.editor.editor_mode = mode;
    }

    editorZoomIn() {
        this.editor.zoom_in();
    }

    editorZoomOut() {
        this.editor.zoom_out();
    }

    editorZoomReset() {
        this.editor.zoom_reset();
    }

    closeBlockPanel() {
        this.blockPanelActive = false;
    }

    openBlockPanel() {
        this.blockPanelActive = true;
    }

    generateNewFlowNameFromExisting(existingFlow: any) {
        const nFlowsWithName = this.savedFlows$.privateFlows.filter(f => f.name === existingFlow.name).length + this.savedFlows$.publishedFlows.filter(f => f.name === existingFlow.name).length;
        return `${existingFlow.name} (${nFlowsWithName})`
    }

    _compareFlows = (flow1: any, flow2: any) => { 
        return flow1 && flow2 && flow1.flowId === flow2.flowId;
    };

    _mapTests(tests: any[]) {
        return tests.map((test) => {
          return {
            'Name': test.name,
            'Test Type': this.autoCxService.getFlow(test.flowId)?.name,
            'Description': test.description
          }
        })
    }

    _mapFlows(flows: any[]) {
        return flows.map((flow) => {
            return {
              'Name': flow.name,
              'Description': flow.description,
              'Blocks': flow.nodes.filter(node => node.opCode != FlowControlBlock.START && node.opCode != FlowControlBlock.STOP).map(node => this.autoCxService.getBlock(node.opCode)?.name).join(',')
            }
        })
    }
}