import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ConfigurationService } from 'src/app/shared/services/configuration.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { BaseWrapperDirective } from 'src/app/shared/classes/base-wrapper';
import { RuleSet } from '../models/site-checkout/RuleSet.model';

export enum FlowControlBlock {
    START = "flow-start",
    STOP = "flow-stop"
}

@Injectable({
    providedIn: 'root'
})
export class FDDService extends BaseWrapperDirective {
    fddUrl: string;

    constructor(
        private readonly http: HttpClient,
        private readonly configService: ConfigurationService,
        private readonly storageService: StorageService
    ) {
        super();
        this.fddUrl = this.configService.get('fddUrl');
    }

    private handleError(error: Error): Promise<any> {
        console.error('An error occurred when communicating with FDD API', error);
        return Promise.reject(error);
    }

    private get jsonTypeHeaders() {
        return {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.storageService.bearerToken}`
            })
        };
    }

    getRuleSetById(ruleSetId: string): Observable<RuleSet> {
        return this.http.get(this.fddUrl + `/diagnostics/rule-sets/${ruleSetId}`, this.jsonTypeHeaders)
        .pipe(catchError(this.handleError));
    }
}
