import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatChipList } from '@angular/material/chips';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { map, startWith, tap } from 'rxjs/operators';
import { ReportsService, WidgetType } from 'src/app/auto-cx/services/reports-service';
import { Helper } from 'src/app/shared/helpers/helper';
import { SiloService } from 'src/app/shared/services/silo-service';

@Component({
  selector: 'app-custom-trend-chart',
  templateUrl: './custom-trend-chart.component.html',
  styleUrls: ['./custom-trend-chart.component.scss']
})
export class CustomTrendChartComponent implements OnInit {

  loading: boolean;
  widgetName: string = 'New Custom Trend Chart';
  groupByScope: string = 'EQUIP_OR_DEVICE';

  tags$;
  filteredTags$;
  entities$;
  separatorKeysCodes: number[] = [ENTER, COMMA];

  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('tagList') tagList: MatChipList;

  points$: any[] = [];

  tagControls$: any[] = [];
  tagControl = new UntypedFormControl('');
  @ViewChild('scrollTarget') private readonly scrollTarget: ElementRef;

  nodes$: Array<any> = [
    {
      name: 'Floors',
      placeHolder: 'Search Floor',
      key: 'floors',
      apiKey: 'floorRef',
      checked: true,
      type: 'group',
      expanded: false,
      children: []
    },
    {
      name: 'Rooms',
      placeHolder: 'Search Room',
      key: 'zones',
      apiKey: 'roomRef',
      checked: true,
      type: 'group',
      expanded: false,
      children: []
    },
    {
      name: 'Equips',
      placeHolder: 'Search Equip',
      key: 'equips',
      apiKey: 'equipRef',
      checked: true,
      expanded: false,
      type: 'group',
      children: []
    },
    {
      name: 'Devices',
      placeHolder: 'Search Devices',
      key: 'devices',
      apiKey: 'deviceRef',
      checked: true,
      expanded: false,
      type: 'group',
      children: []
    }
  ];

  constructor(public dialog: MatDialog,
    public dialogRef: MatDialogRef<CustomTrendChartComponent>,
    @Inject(MAT_DIALOG_DATA) public payload: any,
    public helper: Helper,
    private readonly siloService: SiloService,
    private readonly reportService: ReportsService) { }

  private _filter(value: string): string[] {
    return this.tags$.filter(tag => tag?.toLowerCase().indexOf(value) === 0);
  }

  ngOnInit(): void {

    // Initialize the first tag control
    this.addTagControl();

    this.loading = true;

    this.mapNodes();

    // Get all of the tags for the site
    this.siloService.getTagsForSite(this.payload.siteId).pipe(
      tap((response) => this.tags$ = response),
      tap(() => this.initializeFilteredTags()),
      tap(() => this.loading = false)
    ).subscribe();
  }

  mapNodes() {
    this.nodes$.forEach(node => {
      node.children = this.payload.entities[node.key]?.map(x => {
        return {
          id: x.id,
          name: x.name,
          tags: x.tags,
          selected: true
        }
      }) ?? [];
    });
  }

  addTagControl() {
    const randomId = this.helper.randomId;
    this.tagControls$.forEach(x => x.last = false);
    this.tagControls$.push({ id: `tag${randomId}`, selectedTags: [], last: true });
    setTimeout(() => {
      this.scrollToElement();
    });
  }

  initializeFilteredTags() {
    this.filteredTags$ = this.tagControl.valueChanges.pipe(
      startWith(null as string),
      map((tag: string | null) => tag ? this._filter(tag) : this.tags$?.slice())
    );
  }

  addTag(event: any, id: string) {
    const tag = event.option.viewValue;
    const tagControl = this.tagControls$.find(x => x.id === id);
    if (!tagControl.selectedTags.includes(tag)) {
      this.tagControls$.find(x => x.id === id).selectedTags.push(tag);
      this.tagControls$.find(x => x.id === id).selectedTags.sort();
    }
    this.tagInput.nativeElement.value = '';
    this.tagInput.nativeElement.blur();
    this.tagControl.setValue(null);
  }

  removeTag(tag: string, id: string) {
    const control = this.tagControls$.find(x => x.id === id);
    const tagIndex = control.selectedTags.indexOf(tag);
    control.selectedTags.splice(tagIndex, 1);
  }

  deleteControl(control: any) {
    this.tagControls$ = this.tagControls$.filter(x => x.id !== control.id);
    this.setLastTagControl();
  }

  setLastTagControl() {
    const controlCount = this.tagControls$.length - 1;
    this.tagControls$.forEach((control, index) => {
      control.last = index === controlCount;
    });
  }

  getSelectedTags(id: string) {
    return this.tagControls$.find(x => x.id === id).selectedTags;
  }

  get tagQuery(): string {
    let query = '';
    this.tagControls$.filter(x => x.selectedTags.length > 0).forEach((control, cIndex) => {
      if (control.selectedTags.length > 0) {
        query += cIndex > 0 ? ' or (' : '(';
        query += control.selectedTags.join(' and ');
        query += ')';
      }
    });

    if (this.tagControls$.filter(x => x.selectedTags.length > 0).length > 1) {
      query = `(${query})`;
    }

    return query;
  }


  get scopeQuery() {

    let query = `siteRef==@${this.payload.siteId}`;
    let andQueries = [];
    let orQueries = [];

    // Floors
    const floorNode = this.nodes$.find(x => x.key === 'floors');
    const floors = floorNode.children;
    
    if (floors.some(x => x.selected)) {
      const queries = ['floorRef==@SYSTEM'];
      floors.filter(x => x.selected).forEach(floor => {
        queries.push(`${floorNode.apiKey}==@${floor.id}`);
      });
      andQueries.push(`(${queries.join(' or ')})`);
    } else {
      const queries = ['floorRef==@SYSTEM'];
      floors.forEach(floor => {
        queries.push(`not ${floorNode.apiKey}==@${floor.id}`);
      });
      andQueries.push(`${queries.join(' and ')}`);
    }

    // Rooms
    const roomNode = this.nodes$.find(x => x.key === 'zones');
    const rooms = roomNode.children;
    if (rooms.some(x => x.selected)) {
      const queries = ['roomRef==@SYSTEM'];
      rooms.filter(x => x.selected).forEach(room => {
        queries.push(`${roomNode.apiKey}==@${room.id}`);
      });
      andQueries.push(`(${queries.join(' or ')})`);
    } else {
      const queries = ['roomRef==@SYSTEM'];
      rooms.forEach(room => {
        queries.push(`not ${roomNode.apiKey}==@${room.id}`);
      });
      andQueries.push(`${queries.join(' and ')}`);
    }

    // Equips
    const equipNode = this.nodes$.find(x => x.key === 'equips');
    const equips = equipNode.children;
    if (equips.some(x => x.selected)) {
      const queries = [];
      equips.filter(x => x.selected).forEach(equip => {
        queries.push(`${equipNode.apiKey}==@${equip.id}`);
      });
      andQueries.push(`(${queries.join(' or ')})`);
    } else {
      const queries = [];
      equips.forEach(equip => {
        queries.push(`not ${equipNode.apiKey}==@${equip.id}`);
      });
      andQueries.push(`${queries.join(' and ')}`);
    }

    // Devices
    const deviceNode = this.nodes$.find(x => x.key === 'devices');
    const devices = deviceNode.children;
    if (devices.some(x => x.selected)) {
      const queries = [];
      devices.filter(x => x.selected).forEach(device => {
        queries.push(`${deviceNode.apiKey}==@${device.id}`);
      });
      orQueries.push(`(${queries.join(' or ')})`);
    }

    if (andQueries.length > 0) {
      query += ` and ${andQueries.join(' and ')}`;
    }

    if (orQueries.length > 0) {
      query += ` or (${orQueries.join(' or ')} and ${this.tagQuery})`;
    }
    return query;
  }

  scrollToElement(): void {
    this.scrollTarget.nativeElement.scroll({
      top: this.scrollTarget.nativeElement.scrollHeight,
      left: 0,
      behavior: 'smooth'
    });
  }

  toggleList(node) {
    node.expanded = !node.expanded;
  }

  selectAllChange(event: any, node: any) {
    node.children.forEach(child => {
      child.selected = event.checked;
    });
    node.checked = !node.children.some(x => !x.selected);
  }

  getSelectedCount(group: any) {
    return group.children.filter(x => x.selected).length;
  }

  noEvent(event: any) {
    event.stopPropagation();
  }

  toggleChild(child: any, node: any) {
    child.selected = !child.selected;
    node.checked = !node.children.some(x => !x.selected);
  }

  retrievePoints() {
    const query = `his and point and ${this.tagQuery} and ${this.scopeQuery}`;
    this.loading = true;
    this.siloService.getPointsByQuery(query).pipe(
      tap(() => this.loading = false),
      tap((response) => this.points$ = this.mapPoints(response))
    ).subscribe();
  }

  mapPoints(points: any[]): any[] {
    return points.map(point => {
      return {
        id: point.id,
        name: point.name,
        selected: true
      };
    }).sort((a,b) => a.name.localeCompare(b.name));
  }

  togglePoint(point: any) {
    point.selected = !point.selected;
  }

  selectAllPointsChange(event: any) {
    this.points$.forEach(point => {
      point.selected = event.checked;
    });
  }

  get allPointsChecked() {
    return !this.points$.some(x => !x.selected);
  }

  get hasTags() {
    return this.tagControls$.some(x => x.selectedTags.length > 0);
  }

  saveVisualization() {
    const widget = {
      type: WidgetType.TREND_CHART_BUILDER,
      name: this.widgetName,
      tags: this.tagLists,
      points: this.points,
      scope: {
        equips: this.equips,
        floors: this.floors,
        rooms: this.rooms,
        systems: [], // TODO: Once ccuRef is implemented, this must be populated with CCU devices 
        devices: this.devices
      },
      groupByScope : this.groupByScope
    }
    this.reportService.saveWidget(widget);
    this.close();
  }

  get tagLists() {
    const tagLists = [];
    this.tagControls$.forEach(control => {
      tagLists.push(control.selectedTags);
    });
    return tagLists;
  }

  get points() {
    return this.points$.map(point => point.id);
  }

  get equips() {
    return this.nodes$.find(node => node.key === 'equips').children.map(equip => equip.id);
  }

  get floors() {
    return this.nodes$.find(node => node.key === 'floors').children.map(floor => floor.id);
  }

  get rooms() {
    return this.nodes$.find(node => node.key === 'zones').children.map(zone => zone.id);
  }

  get devices() {
    return this.nodes$.find(node => node.key === 'devices').children.map(device => device.id);
  }

  close() {
    this.dialogRef.close();
  }

}
